import { sumBy } from "lodash";
import {
  distributeByPercent,
  distributeEqually,
  distributeProportionally,
  roundUnit,
} from "../../../../lib/formats";
import {
  CropFieldFragment,
  CropFieldProgressFragment,
  CropFieldShortFragment,
  UnitType,
  WorkOrderCostCenterEmployeeFragment,
  WorkOrderCostCenterFragment,
  WorkOrderCostCenterMachineFragment,
  WorkOrderCostCenterVariantFragment,
  WorkOrderVariantFragment,
} from "../../../../lib/graphql";
import { Destroy } from "../../../../lib/hooks";
import { WorkOrderBaseBuilder } from "./base";
import { WorkOrderBuilder } from ".";
import { CostCenterMetricBuilder } from "./metrics/costCenterMetrics";
import { debounce } from "../../../../lib/utils";

interface WorkOrderCostCenterVariant
  extends Destroy<WorkOrderCostCenterVariantFragment> {
  dayGoal?: number;
}

type WorkOrderCostCenterEmployee = Destroy<WorkOrderCostCenterEmployeeFragment>;
type WorkOrderCostCenterMachine = Destroy<WorkOrderCostCenterMachineFragment>;

export interface WorkOrderCostCenter
  extends Omit<
    Destroy<WorkOrderCostCenterFragment>,
    "variants" | "employees" | "machines"
  > {
  progressPercent?: number;
  employeePercent?: number;
  machinePercent?: number;
  variants: WorkOrderCostCenterVariant[];
  employees: WorkOrderCostCenterEmployee[];
  machines: WorkOrderCostCenterMachine[];
}

export class WorkOrderCostCenterBuilder extends WorkOrderBaseBuilder<WorkOrderCostCenter> {
  metrics: CostCenterMetricBuilder;

  public debouncedOnTotalProgressChanged = debounce(
    this.onTotalProgressChanged
  );

  constructor(builder: WorkOrderBuilder) {
    super(builder);

    this.metrics = new CostCenterMetricBuilder(builder);
  }

  protected get field() {
    return "costCenters";
  }

  sorter(a: WorkOrderCostCenter, b: WorkOrderCostCenter) {
    return (a.profitableName || a.costCenter.name).localeCompare(
      b.profitableName || b.costCenter.name
    );
  }

  getTotalProgress() {
    return roundUnit(sumBy(this.get(), "totalProgress"));
  }

  getTotalGoal() {
    return roundUnit(sumBy(this.get(), "dayGoal"));
  }

  getTotalPercentage() {
    return roundUnit(
      sumBy(this.get(), (cf) => cf.progressPercent || 0) / 100.0
    );
  }

  getArea(cropField: Pick<CropFieldFragment, "effectiveArea" | "totalArea">) {
    return this.workOrder.activity.useTotalArea
      ? cropField.totalArea
      : cropField.effectiveArea;
  }

  getTotalArea() {
    return sumBy(this.get(), (cc) =>
      cc.cropField ? this.getArea(cc.cropField) : 0
    );
  }

  getProgressToDistribute() {
    if (this.builder.tokens.hasItems)
      return this.builder.tokens.totalHarvested();
    if (this.builder.progressByGroup) return this.getTotalProgress();

    return roundUnit(
      this.builder.employees.getTotalProgress() +
        this.builder.machines.getTotalProgress()
    );
  }

  fieldName(costCenterIndex: number, field: string) {
    return ["costCenters", costCenterIndex, field];
  }

  init() {
    this.metrics.initMetricFields();

    if (this.builder.allowProgress) {
      this.initProgressFields();
      if (this.builder.showDayGoal && !this.builder.isReadonly)
        this.initDayGoal();
    } else {
      this.calculateCostDistribution(false);
    }
  }

  add(costCenter: WorkOrderCostCenter) {
    const costCenters = this.get(false);
    const existing = costCenters.find(
      (c) =>
        c.costCenter.id === costCenter.costCenter.id &&
        c.profitableId == costCenter.profitableId
    );

    if (existing) {
      if (existing._destroy) {
        existing._destroy = false;

        // hack to update table
        this.form.setFieldValue("costCenters", []);
        this.form.setFieldValue("costCenters", [...costCenters]);
        this.form.setFieldValue(
          ["costCenters", this.indexOf(existing), "_destroy"],
          false
        );
      } else {
        return;
      }
    } else {
      this.form.setFieldValue("costCenters", [...costCenters, costCenter]);
    }

    this.form.validateFields(["costCenters"]);
  }

  variantFieldName(
    costCenterIndex: number,
    input: WorkOrderVariantFragment,
    field: string
  ) {
    const variants = this.get(false)[costCenterIndex].variants;
    const index = variants.findIndex((cv) => cv.variantId === input.variant.id);

    return ["costCenters", costCenterIndex, "variants", index, field];
  }

  shouldUpdateCostCenterVariant(
    costCenterIndex: number,
    input: WorkOrderVariantFragment
  ) {
    return (prev: any, next: any) =>
      prev.costCenters[costCenterIndex].variants.find(
        (v: any) => v.variantId === input.variant.id
      ) !==
      next.costCenters[costCenterIndex].variants.find(
        (v: any) => v.variantId === input.variant.id
      );
  }

  calculateDayGoal(
    cropField: CropFieldShortFragment & { progress?: number | null },
    plannedProgress?: number | null,
    actualProgress?: number | null
  ) {
    if (plannedProgress)
      return Math.max(roundUnit(plannedProgress - (actualProgress || 0)), 0);

    const progressUnit = this.workOrder.activity.progressUnit;
    const areaUnit = progressUnit.unitType === UnitType.Area;
    const plantUnit = progressUnit.abbrEn == "pl";

    if (!areaUnit && !plantUnit) return 0;

    const totalAmount = areaUnit
      ? this.getArea(cropField) * this.builder.areaConversionFactor
      : cropField.effectivePlants;

    return Math.max(roundUnit(totalAmount - (cropField.progress || 0)), 0);
  }

  initDayGoal() {
    const costCenters = this.get();
    costCenters.forEach((cc) => {
      if (cc.dayGoal == null && cc.cropField) {
        cc.dayGoal = this.calculateDayGoal(
          cc.cropField,
          cc.plannedProgress,
          cc.actualProgress
        );
      }
    });
  }

  recalculateProgressPercentage() {
    const costCenters = this.get();
    const toDistribute = this.getProgressToDistribute();

    costCenters.forEach((cc) => {
      const progressPercent = toDistribute
        ? (cc.totalProgress * 100.0) / toDistribute
        : 0;

      this.form.setFields([
        {
          name: ["costCenters", this.indexOf(cc), "progressPercent"],
          value: roundUnit(progressPercent),
        },
      ]);
    });
  }

  initProgressFields() {
    const costCenters = this.getCostCenters();

    // prefill daygoal as progress
    if (this.builder.progressByGroup && !this.getTotalProgress()) {
      costCenters.forEach((cc) => (cc.totalProgress = cc.dayGoal));
      this.builder.progress.redistributeCostCentersProgress();
    }

    const distribution = distributeProportionally(
      100,
      costCenters.map((cc) => cc.totalProgress)
    );

    costCenters.forEach((cc, index) => {
      if (cc.progressPercent) return;
      this.form.setFields([
        {
          name: ["costCenters", this.indexOf(cc), "progressPercent"],
          value: roundUnit(distribution[index]),
        },
      ]);
    });
  }

  onTotalProgressChanged(ccIndex: number, val?: number | null) {
    this.recalculateProgressPercentage();

    if (this.builder.progressByIndividual) {
      this.recalculateProgressStorage();
    } else {
      this.builder.progress.distributeCostCenterProgress(ccIndex, val);
      this.builder.outputs.recalculateVariants();
    }
  }

  onDayGoalChanged() {
    this.builder.inputs.onDayGoalChanged();
    this.builder.waterUsage.recalculate();
  }

  // calculate cc machine/employee/variants progress
  recalculateProgressStorage() {
    this.builder.progress.recalculateCropFieldWorkerProgress();
    this.builder.outputs.recalculateVariants();
  }

  recalculateCropFieldTotalProgress() {
    const total = this.getProgressToDistribute();

    const costCenters = this.getCostCenters();
    const distribution = distributeByPercent(
      total || 0,
      costCenters.map((cc) => cc.progressPercent)
    );

    costCenters.forEach((cc, ccIndex) => {
      this.form.setFields([
        {
          name: ["costCenters", this.indexOf(cc), "totalProgress"],
          value: distribution[ccIndex],
        },
      ]);
    });

    this.recalculateProgressStorage();
  }

  onRemove(costCenter: WorkOrderCostCenter) {
    super.onRemove(costCenter);

    if (this.builder.progressByIndividual)
      this.builder.progress.redistributeTotalProgress();
    this.builder.inputs.recalculateAll();

    if (!this.builder.allowProgress) this.calculateCostDistribution(true);

    if (this.builder.isSpraying) this.builder.waterUsage.recalculate();
  }

  calculateCostDistribution(force: boolean) {
    const costCenters = this.get();
    const employeeHours = this.builder.employees.getTotalHours();
    const machineHours = this.builder.machines.getTotalHours();

    const distribute = (value: number) =>
      this.builder.isAgricultural
        ? distributeProportionally(
            value,
            costCenters.map((cc) => cc.cropField?.effectiveArea)
          )
        : distributeEqually(value, costCenters.length);

    const employeeHoursDistribution = distribute(employeeHours);
    const machineHoursDistribution = distribute(machineHours);

    costCenters.forEach((cc, ccIndex) => {
      const eHours =
        cc.employeeHours == null || force
          ? employeeHoursDistribution[ccIndex]
          : cc.employeeHours;
      const mHours =
        cc.machineHours == null || force
          ? machineHoursDistribution[ccIndex]
          : cc.machineHours;

      const employeePercent = employeeHours
        ? roundUnit((100 * eHours) / employeeHours, 1000)
        : 0;
      const machinePercent = machineHours
        ? roundUnit((100 * mHours) / machineHours, 1000)
        : 0;
      const index = this.indexOf(cc);

      this.form.setFields([
        {
          name: ["costCenters", index, "employeePercent"],
          value: employeePercent,
        },
        {
          name: ["costCenters", index, "machinePercent"],
          value: machinePercent,
        },
        {
          name: ["costCenters", index, "employeeHours"],
          value: eHours,
        },
        {
          name: ["costCenters", index, "machineHours"],
          value: mHours,
        },
      ]);
    });
  }

  distributeCostsByPercent() {
    const costCenters = this.get();
    const employeeHours = this.builder.employees.getTotalHours();
    const machineHours = this.builder.machines.getTotalHours();

    const employeeHoursDistribution = distributeByPercent(
      employeeHours,
      costCenters.map((cc) => cc.employeePercent)
    );

    const machineHoursDistribution = distributeByPercent(
      machineHours,
      costCenters.map((cc) => cc.machinePercent)
    );

    costCenters.forEach((cc, index) => {
      const ccIndex = this.indexOf(cc);

      this.form.setFields([
        {
          name: ["costCenters", ccIndex, "employeeHours"],
          value: employeeHoursDistribution[index],
        },
        {
          name: ["costCenters", ccIndex, "machineHours"],
          value: machineHoursDistribution[index],
        },
      ]);
    });
  }

  updateProgress(cropFields: Array<CropFieldProgressFragment>) {
    const costCenters = this.get();

    costCenters.forEach((cc, ccIndex) => {
      const cf = cropFields.find((cf) => cf.id == cc.cropField?.id);
      if (!cf) return;

      this.form.setFields([
        {
          name: ["costCenters", ccIndex, "actualProgress"],
          value: cf.actualProgress,
        },
        {
          name: ["costCenters", ccIndex, "plannedProgress"],
          value: cf.plannedProgress,
        },
        {
          name: ["costCenters", ccIndex, "estimatedHarvestAmount"],
          value: cf.estimatedHarvestAmount,
        },
      ]);

      if (this.builder.isOpen) {
        const value = this.calculateDayGoal(
          cf,
          cf.plannedProgress,
          cf.actualProgress
        );
        if (cc.dayGoal != value) {
          this.form.setFields([
            {
              name: ["costCenters", ccIndex, "dayGoal"],
              value,
            },
          ]);

          this.builder.inputs.onDayGoalChanged();
        }
      }
    });
  }
}
