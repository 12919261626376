import { FormattedMessage } from "react-intl";
import { TableInput } from "../../shared";
import { get } from "lodash";
import {
  ItemKind,
  InventoryStatus,
  WorkOrderVariantFragment,
  WorkOrderCostCenterFragment,
} from "../../../lib/graphql";
import {
  formatUnitValue,
  roundUnit,
  formatCostCenterName,
  formatNumber,
  formatUnitValueConversion,
  variantSelectDropdown,
  formatVariantLink,
  formatPercentStatistic,
} from "../../../lib/formats";

import { Key, useContext, useEffect, useState } from "react";
import { WorkOrderContext } from "./WorkOrderContext";
import { Form, Rules, InputNumber, RuleBuilder } from "../../form";
import { Button, Typography } from "antd";
import { useStockVariantOptions } from "../../../lib/hooks/inventory/variants";
import { UnitSelect } from "../../units";
import { EditOutlined } from "@ant-design/icons";
import { useCurrentUser, useItemSidebarContext } from "../../../lib/hooks";
import {
  CurrentStockTooltip,
  EditInventoryRequestSidebar,
  InventoryRequestWarehouseCell,
} from "./inventory";
import { WorkOrderVariant } from "./builder/tools";
import { filterColumns } from "../../../lib/utils";

function shouldUpdate(prev: any, next: any) {
  return (
    prev.costCenters !== next.costCenters ||
    prev.inputs.length !== next.inputs.length ||
    prev.costCenters.findIndex(
      (c: WorkOrderCostCenterFragment, index: number) => {
        const cc = next.costCenters[index];
        return c.variants !== cc.variants;
      }
    ) >= 0
  );
}

export function WorkOrderConsumedInputs({
  readonly,
  byProgress,
  noDosages = false,
}: {
  readonly: boolean;
  byProgress: boolean;
  noDosages?: boolean;
}) {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const { currentTenant } = useCurrentUser();
  const [editIndex, setEditIndex] = useState(0);
  const { setCurrentAction } = useItemSidebarContext();
  const [expandedRows, setExpandedRows] = useState<readonly Key[]>([]);

  useEffect(() => {
    if (!byProgress)
      setExpandedRows(builder.inputs.get().map((i) => i.variant.id));
  }, [byProgress, builder]);

  const columns = filterColumns<WorkOrderVariant>([
    {
      title: <FormattedMessage id="variants.entityName" />,
      dataIndex: "variant",
      render: (_, v) => formatVariantLink(v.variant),
    },
    !builder.isCompleted &&
      currentTenant.features.inventoryStock && {
        width: "1rem",
        render: (_, v) => (
          <CurrentStockTooltip
            variant={v}
            date={workOrder.documentDate}
            localityId={workOrder.locality.id}
          />
        ),
      },
    currentTenant.inventoryRequestEnabled && {
      title: (
        <FormattedMessage id="workOrders.consumed" defaultMessage="consumed" />
      ),
      dataIndex: "totalConsumed",
      width: 150,
      align: "center",
      render: (_, v) => (
        <Form.Item noStyle shouldUpdate={shouldUpdate}>
          {() => {
            return formatUnitValueConversion(
              builder.inputs.getTotalConsumed(v),
              {
                unit: v.unit,
                hideConversion: builder.canEdit,
                conversionFactor: v.conversionFactor,
                conversionUnit: v.variant.variationUnit,
              }
            );
          }}
        </Form.Item>
      ),
    },
    {
      title: !currentTenant.inventoryRequestEnabled ? (
        <FormattedMessage id="workOrders.consumed" defaultMessage="consumed" />
      ) : (
        <FormattedMessage
          id="workOrders.requested"
          defaultMessage="requested"
        />
      ),
      dataIndex: "totalAmount",
      width: "14rem",
      align: "center",
      render: (_, v, index) =>
        v.status == InventoryStatus.NotRequested && byProgress ? (
          <Form.Item
            compact
            name={["inputs", index, "totalAmount"]}
            rules={[Rules.gtEqZero]}
          >
            <InputNumber
              step={0.1}
              min={0}
              precision={4}
              addonAfter={
                <UnitSelect.FormItem
                  name={["inputs", index, "unit"]}
                  unitTypes={[v.variant.variationUnit.unitType]}
                />
              }
              onChange={() =>
                builder.inputs.recalculateAmounts(index, byProgress)
              }
            />
          </Form.Item>
        ) : (
          <>
            {formatUnitValueConversion(v.totalAmount, {
              unit: v.unit,
              hideConversion: builder.canEdit,
              conversionFactor: v.conversionFactor,
              conversionUnit: v.variant.variationUnit,
            })}
            {!readonly && byProgress && (
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  setEditIndex(index);
                  setCurrentAction("editInput");
                }}
                style={{
                  position: "absolute",
                  right: "20px",
                  marginTop: "-5px",
                }}
              />
            )}
          </>
        ),
    },
    currentTenant.inventoryRequestEnabled && {
      title: (
        <FormattedMessage
          id="workOrders.remainder"
          defaultMessage="remainder"
        />
      ),
      dataIndex: "returnedAmount",
      width: 150,
      align: "center",
      render: (_, v, index) =>
        readonly || !byProgress ? (
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              get(prev, ["inputs", index, "returnedAmount"]) !==
              get(next, ["inputs", index, "returnedAmount"])
            }
          >
            {({ getFieldValue }) => {
              const returnedAmount = getFieldValue([
                "inputs",
                index,
                "returnedAmount",
              ]);

              return formatUnitValue(returnedAmount, v.unit, null, {
                maximumFractionDigits: 4,
              });
            }}
          </Form.Item>
        ) : (
          <Form.Item
            compact
            name={["inputs", index, "returnedAmount"]}
            rules={[Rules.gtEqZero, RuleBuilder.ltEq(v.totalAmount)]}
          >
            <InputNumber
              step={0.1}
              min={0}
              precision={4}
              addonAfter={v.unit.abbr}
              onChange={() =>
                builder.inputs.recalculateAmounts(index, byProgress)
              }
            />
          </Form.Item>
        ),
    },
    currentTenant.features.inventoryStock && {
      title: (
        <FormattedMessage
          id={
            !currentTenant.inventoryRequestEnabled
              ? "inventoryRequests.sourceWarehouse"
              : "workOrders.inventoryStatus"
          }
        />
      ),
      width: 150,
      align: "center",
      render: (_, _v, index) => (
        <InventoryRequestWarehouseCell name={["inputs", index]} />
      ),
    },
  ]);

  return (
    <>
      <EditInventoryRequestSidebar
        actionName="editInput"
        variant={builder.inputs.getBy(editIndex)}
        onChanged={(value) => {
          builder.form.setFieldValue(["inputs", editIndex], value);
          builder.inputs.updateInput(editIndex);
        }}
      />

      <Form.Item noStyle shouldUpdate={shouldUpdate}>
        {({ getFieldValue }) => {
          if (!readonly) {
            builder.inputs.initInputs(byProgress);
          }

          const costCenterColumns = (
            variant: WorkOrderVariantFragment,
            variantIndex: number
          ) =>
            filterColumns<WorkOrderCostCenterFragment>([
              {
                dataIndex: "name",
                render: (_, cc) => (
                  <>
                    {cc.profitableName || cc.costCenter.name}
                    <br />
                    <Typography.Text
                      type="secondary"
                      style={{ fontSize: "12px" }}
                    >
                      {noDosages ? (
                        formatCostCenterName(cc.costCenter.parentCostCenter)
                      ) : (
                        <span>
                          <FormattedMessage id="total" />:{" "}
                          {formatNumber(cc.totalProgress)}{" "}
                          {workOrder.activity.progressUnit.abbr}
                        </span>
                      )}
                    </Typography.Text>
                  </>
                ),
              },
              {
                title: (
                  <FormattedMessage
                    id="workOrders.amountConsumed"
                    defaultMessage="amountConsumed"
                  />
                ),
                width: 200,
                align: "center",
                render: (_, _cc, index) => {
                  const name = builder.costCenters.variantFieldName(
                    index,
                    variant,
                    "consumedAmount"
                  );

                  if (readonly || byProgress) {
                    return (
                      <Form.Item
                        noStyle
                        shouldUpdate={builder.costCenters.shouldUpdateCostCenterVariant(
                          index,
                          variant
                        )}
                      >
                        {() => {
                          const consumedAmount = getFieldValue(name);

                          if (builder.isCompleted) {
                            return formatPercentStatistic(
                              consumedAmount,
                              getFieldValue(
                                builder.costCenters.variantFieldName(
                                  index,
                                  variant,
                                  "amount"
                                )
                              ),
                              {
                                formatter: (v) =>
                                  formatUnitValue(v, variant.unit, null, {
                                    maximumFractionDigits: 4,
                                  }),
                              }
                            );
                          }

                          return formatUnitValue(
                            consumedAmount,
                            variant.unit,
                            null,
                            { maximumFractionDigits: 4 }
                          );
                        }}
                      </Form.Item>
                    );
                  }

                  return (
                    <Form.Item
                      compact
                      name={name}
                      rules={[
                        Rules.gtEqZero,
                        {
                          validator(_: any, value: number) {
                            if (index < builder.costCenters.get().length - 1)
                              return Promise.resolve();

                            const consumed = roundUnit(
                              builder.inputs.getTotalConsumed(variant)
                            );

                            if (consumed <= variant.totalAmount) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              <FormattedMessage
                                id="form.validation.ltEq"
                                values={{
                                  max: roundUnit(
                                    variant.totalAmount - (consumed - value)
                                  ),
                                }}
                              />
                            );
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        step={0.1}
                        min={0}
                        precision={4}
                        addonAfter={variant.unit.abbr}
                        onChange={() =>
                          builder.inputs.recalculateAmounts(
                            variantIndex,
                            byProgress,
                            index
                          )
                        }
                      />
                    </Form.Item>
                  );
                },
              },
              !noDosages && {
                title: <FormattedMessage id="dosage" defaultMessage="dosage" />,
                width: 230,
                align: "center",
                render: (_, cc, index) => {
                  const name = builder.costCenters.variantFieldName(
                    index,
                    variant,
                    "consumedAmount"
                  );

                  return (
                    <Form.Item
                      noStyle
                      shouldUpdate={builder.costCenters.shouldUpdateCostCenterVariant(
                        index,
                        variant
                      )}
                    >
                      {() => {
                        const consumedAmount = getFieldValue(name);

                        if (!cc.totalProgress) return;

                        if (builder.isCompleted) {
                          return formatPercentStatistic(
                            consumedAmount / cc.totalProgress,
                            getFieldValue(
                              builder.costCenters.variantFieldName(
                                index,
                                variant,
                                "amount"
                              )
                            ) / cc.dayGoal,
                            {
                              formatter: (v) =>
                                formatUnitValue(
                                  v,
                                  variant.unit,
                                  workOrder.activity.progressUnit.abbr,
                                  { maximumFractionDigits: 4 }
                                ),
                            }
                          );
                        }

                        return formatUnitValue(
                          consumedAmount / cc.totalProgress,
                          variant.unit,
                          workOrder.activity.progressUnit.abbr,
                          { maximumFractionDigits: 4 }
                        );
                      }}
                    </Form.Item>
                  );
                },
              },
            ]);

          return (
            <TableInput
              name="inputs"
              tableProps={{
                bordered: true,
                expandable: {
                  rowExpandable: (variant) =>
                    builder.costCenters.hasItems && Form.undestroyed(variant),
                  defaultExpandAllRows: true,
                  expandedRowKeys: expandedRows,
                  onExpandedRowsChange: setExpandedRows,
                  expandedRowRender: (variant, variantIndex) => {
                    return (
                      <TableInput
                        name="costCenters"
                        dataSource={workOrder.costCenters}
                        hideMenu
                        tableProps={{
                          size: "small",
                        }}
                        columns={costCenterColumns(variant, variantIndex)}
                      />
                    );
                  },
                },
              }}
              dataSource={workOrder.inputs}
              rowKey={(f) => f.variant.id}
              disabled={readonly}
              tableSelectProps={{
                mode: "multiple",
                optionsHook: useStockVariantOptions,
                optionsHookParams: {
                  variables: {
                    localityId: workOrder.locality.id,
                    date: workOrder.documentDate,
                    filter: { itemKind: [ItemKind.Consumable] },
                  },
                },
                placeholder: (
                  <FormattedMessage
                    id="select.variants"
                    defaultMessage="variants"
                  />
                ),
                dropdownRender: variantSelectDropdown({
                  hideRight: !currentTenant.features.inventoryStock,
                }),
                entityById: (_, { variant }) => {
                  if (variant) {
                    return {
                      id: "",
                      variant,
                      totalAmount: 0,
                      status: InventoryStatus.NotRequested,
                      unit: variant.variationUnit,
                    };
                  }
                },
              }}
              addSorter={(a, b) => builder.inputs.sorter(a, b)}
              onRemove={(v) => builder.inputs.onRemove(v)}
              allowBulkRemove
              rules={[
                {
                  validator() {
                    if (
                      !builder.isSubmitting ||
                      builder.inputs.isValidConsumption()
                    )
                      return Promise.resolve();

                    return Promise.reject(
                      <FormattedMessage
                        id="workOrders.inputsError"
                        defaultMessage="inputsError"
                      />
                    );
                  },
                },
              ]}
              columns={columns}
            />
          );
        }}
      </Form.Item>
    </>
  );
}
