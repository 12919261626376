import { formatTags } from "./tags";
import {
  CropStage,
  CropFieldFragment,
  CropFieldShortFragment,
  CropVariety,
  UnitFragment,
  UnitType,
  DiseaseFragment,
  SampleDiseaseFragment,
  CropCycleFragment,
  MetricValueFragment,
} from "../graphql";
import {
  Col,
  Progress,
  ProgressProps,
  Row,
  Space,
  Switch,
  Tag,
  Typography,
} from "antd";
import {
  FormatUnitProps,
  formatUnitValue,
  getUnitsConversionFactor,
} from "./units";
import {
  colors,
  formatDetailsLink,
  formatImage,
  formatInfoTooltip,
  formatNumber,
  roundUnit,
} from ".";
import { ReactNode, useState } from "react";
import { blue, green, volcano } from "@ant-design/colors";
import { WithConditionalIDType, useCurrentUser } from "../hooks";
import { FormattedMessage } from "react-intl";
import routes from "../routes";
import { TextProps } from "antd/lib/typography/Text";
import { ColumnType } from "antd/lib/table";

export function formatActivity(
  activityName: string,
  cycleNumber?: number | null
) {
  if (!cycleNumber) return activityName;

  return `${activityName} #${cycleNumber}`;
}

export function formatFormula(formula: string) {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: formula.replace(/(\d+?)/g, "<sub>$1</sub>"),
      }}
    />
  );
}

export function formatCropStage(stage: Pick<CropStage, "name">) {
  return (
    <Tag key={stage.name} color="blue">
      {stage.name}
    </Tag>
  );
}

export function formatCropFieldGroup(group?: string | null, color = "purple") {
  if (!group) return null;
  return formatTags([group], color);
}

export function formatCropFieldVarieties(
  cropField: Pick<CropFieldFragment, "crop" | "cropFieldVarieties">
) {
  return (
    <>
      {formatCropVarieties(
        ...cropField.cropFieldVarieties
          .slice()
          .sort((a, b) => b.effectiveArea - a.effectiveArea)
          .map((v) => v.cropVariety)
      )}
    </>
  );
}

export function formatCropVarieties(...varieties: Pick<CropVariety, "name">[]) {
  return formatTags(
    varieties.map((v) => v.name),
    "lime"
  );
}

export function formatCropFieldName(field: CropFieldShortFragment) {
  return (
    <>
      {field.name} {formatCropFieldGroup(field.group)}
    </>
  );
}

export function formatCropFieldOption({
  field,
  useTotalArea,
}: {
  field: CropFieldFragment;
  useTotalArea?: boolean;
}) {
  return (
    <>
      {field.name}{" "}
      <Typography.Text type="secondary">
        (
        {formatUnitValue(
          useTotalArea ? field.totalArea : field.effectiveArea,
          field.locality.areaUnit
        )}
        )
      </Typography.Text>{" "}
      {formatCropFieldGroup(field.group)}
      {formatCropStage(field.cropStage)}
      {formatCropFieldVarieties(field)}
      {formatTags(field.tags)}
    </>
  );
}

export function formatCropCycleCrops(
  cropCycle: Pick<CropCycleFragment, "crops">
) {
  return cropCycle.crops.map((c) => c.name).join(" / ");
}

export function formatProgress(
  total: number,
  progressUnit: FormatUnitProps["unit"],
  progress?: number | null,
  progressProps?: ProgressProps & { supressOverload?: boolean },
  textProps?: TextProps
) {
  const { supressOverload, ...props } = progressProps || {};

  const percentage = !progress ? 0 : roundUnit((progress / total) * 100);
  const color =
    percentage == 100
      ? green
      : percentage > 100 && !supressOverload
      ? volcano
      : blue;

  return (
    <>
      <Typography.Text {...textProps}>
        {formatUnitValue(progress || 0, progressUnit)} /{" "}
        {formatUnitValue(total, progressUnit)}{" "}
      </Typography.Text>
      <Progress
        percent={percentage}
        size="small"
        format={() => percentage + "%"}
        status="normal"
        strokeColor={color.primary}
        {...props}
        style={{ paddingRight: "1.5em", ...progressProps?.style }}
      />
    </>
  );
}

export function getAreaConversionFactor(
  progressUnit: Pick<UnitFragment, "id" | "unitType" | "conversionFactor">,
  areaUnit: Pick<UnitFragment, "id" | "conversionFactor">
) {
  return progressUnit.unitType !== UnitType.Area ||
    progressUnit.id == areaUnit.id
    ? 1
    : getUnitsConversionFactor(progressUnit, areaUnit);
}

export function formatUnplanned(
  entity: WithConditionalIDType,
  content: ReactNode
) {
  if (entity.id != null) return content;

  return (
    <Row justify="space-between">
      <Col>{content}</Col>
      <Col>
        <Tag color="gold">
          <FormattedMessage
            id="plannings.unplanned"
            defaultMessage="Unplanned"
          />
        </Tag>
      </Col>
    </Row>
  );
}

type FormatCropFieldOptions = { showTags?: boolean; inline?: boolean };

export function formatCropField(
  cropField?: Pick<
    CropFieldFragment,
    | "id"
    | "name"
    | "discardedAt"
    | "cropStage"
    | "group"
    | "cropFieldVarieties"
    | "crop"
    | "tags"
  > | null,
  options: FormatCropFieldOptions = { showTags: false, inline: false }
) {
  return (
    cropField && (
      <>
        {formatDetailsLink({
          id: cropField.id,
          route: routes.agro.cropFields.details,
          title: cropField.name,
          discarded: !!cropField.discardedAt,
        })}
        {!options.inline && <br />}
        {formatCropStage(cropField.cropStage)}
        {formatCropFieldGroup(cropField.group)}
        {formatCropFieldVarieties(cropField)}
        {options.showTags && formatTags(cropField.tags)}
      </>
    )
  );
}

export function formatCropFieldArea(
  cropField?: Pick<
    CropFieldFragment,
    "effectiveArea" | "effectivePlants" | "locality" | "totalArea"
  > | null,
  useTotalArea: boolean | null | undefined = false,
  showPlants: boolean | null | undefined = true
) {
  if (!cropField) return;

  const area = formatUnitValue(
    useTotalArea ? cropField.totalArea : cropField.effectiveArea,
    cropField.locality.areaUnit
  );

  const plants = (
    <>
      {formatNumber(cropField.effectivePlants)}{" "}
      <FormattedMessage id="plants.abbr" />
    </>
  );

  return (
    <>
      {area}
      <br />
      {showPlants && <Tag color="lime">{plants}</Tag>}
    </>
  );
}

export function formatDiseaseOption(
  disease: Pick<DiseaseFragment, "thumbnail" | "name">
) {
  return (
    <Space>
      {formatImage(disease.thumbnail)} {disease.name}
    </Space>
  );
}

export function formatDiseaseSampleRisk(
  sample: Pick<SampleDiseaseFragment, "disease" | "size" | "found">
) {
  const percent = roundUnit((100 * sample.found) / sample.size);

  return (
    <Progress
      status="normal"
      strokeColor={
        !sample.disease
          ? undefined
          : percent > sample.disease.riskMap.high
          ? colors.dangerColor
          : percent > sample.disease.riskMap.low
          ? colors.warningColor
          : colors.successColor
      }
      percent={percent}
      size="small"
    />
  );
}

export function useEstimationUnitSwitch() {
  const { currentCropCycle } = useCurrentUser();
  const [showInEstimationUnits, setShowInEstimationUnits] =
    useState<boolean>(false);

  if (!currentCropCycle?.harvestEstimate) return {};

  const { harvestEstimate } = currentCropCycle;

  return {
    showInEstimationUnits,
    showInEstimationUnitsSwitch: (
      <Space>
        <Switch
          unCheckedChildren={harvestEstimate.harvestUnit.abbr.toUpperCase()}
          checkedChildren={harvestEstimate.estimateUnit.abbr.toUpperCase()}
          checked={showInEstimationUnits}
          onChange={setShowInEstimationUnits}
        />
      </Space>
    ),
  };
}

export function buildMetricColumns<
  T extends { metrics: MetricValueFragment[] }
>(
  items?: Array<T> | null,
  render?: (metric: MetricValueFragment, record: T) => ReactNode
) {
  return items
    ? Object.values(
        items.reduce((cols, item) => {
          item.metrics.forEach((m) => {
            if (cols[m.metric.id]) return;

            cols[m.metric.id] = {
              key: m.metric.id,
              title: (
                <>
                  {m.metric.name} {formatInfoTooltip(m.metric.description)}
                </>
              ),
              render: (_, a) => {
                const metric = a.metrics.find(
                  (am) => am.metric.id == m.metric.id
                );
                if (!metric) return;

                if (render) return render(metric, a);

                return formatUnitValue(metric.value, metric.metric.unit);
              },
              width: "12rem",
            };
          });
          return cols;
        }, {} as Record<string, ColumnType<T>>)
      )
    : [];
}
