import { WorkOrderCostCenter } from "../costCenters";
import { WorkOrderMachine } from "../machines";
import { ProgressBuilder } from "./progress";

export class MachineProgress extends ProgressBuilder<WorkOrderMachine> {
  protected get field() {
    return "machines";
  }

  getWorkers() {
    return this.builder.machines.get();
  }

  protected findOrCreateCostCenterProgress(
    costCenter: WorkOrderCostCenter,
    machine: WorkOrderMachine
  ): number | null {
    const ccm = costCenter.machines.find(
      (ccm) => ccm.machineId === machine.machine.id
    );

    if (ccm) return ccm.progress;

    costCenter.machines.push({
      id: "",
      machineId: machine.machine.id,
      progress: 0,
      hours: 0,
    });

    return null;
  }

  protected findWorkerIndex(
    costCenter: WorkOrderCostCenter,
    machine: WorkOrderMachine
  ) {
    return costCenter.machines.findIndex(
      (e) => e.machineId === machine.machine.id
    );
  }
}
