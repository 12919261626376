import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { CropCycleShortFragment } from "../../lib/graphql";
import {
  DateTimeType,
  WeekdaysShort,
  formatWeek,
  shouldUpdate,
} from "../../lib/formats";
import dayjs, { Dayjs } from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker, {
  PickerDateProps,
  RangePickerProps as AntRangePickerProps,
} from "antd/lib/date-picker/generatePicker";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekday from "dayjs/plugin/weekday";
import isBetween from "dayjs/plugin/isBetween";
import localeData from "dayjs/plugin/localeData";
import minMax from "dayjs/plugin/minMax";
import { useCurrentLocale, useWeekDaysAndHolidays } from "../../lib/hooks";
import classNames from "classnames";
import { TIME_FORMAT } from "./TimePicker";
import { Form, NamePath } from "./Form";
import { useState } from "react";

dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);
dayjs.extend(isBetween);
dayjs.extend(minMax);

// AndDatePicker first time returns date w/o timzone, fix it by adding timezone to now
const tzGenerateConfig = {
  ...dayjsGenerateConfig,
  getNow: () => dayjs().tz(),
};
export const AntDatePicker = generatePicker<dayjs.Dayjs>(
  tzGenerateConfig as any
);

export interface DatePickerProps
  extends Omit<PickerDateProps<Dayjs>, "picker"> {
  showWeeks?:
    | boolean
    | Pick<CropCycleShortFragment, "startedAt" | "estimatedEndDate">
    | null;
  disableDates?: boolean | DateTimeType;
  popupClassName?: string | undefined;
  defaultTime?: "startOfDay";
}

export type RangePickerProps = AntRangePickerProps<Dayjs>;

const DATE_FORMAT = "DD/MM/YYYY";
const MONTH_FORMAT = "MM/YYYY";

const messages = defineMessages({
  selectDate: {
    id: "selectDate",
    defaultMessage: "Select Date",
  },
  selectMonth: {
    id: "selectMonth",
    defaultMessage: "Select Month",
  },
  selectYear: {
    id: "selectYear",
    defaultMessage: "Select Year",
  },
  selectWeek: {
    id: "selectWeek",
    defaultMessage: "Select Week",
  },
});

// dayjs("2023-03-13T22:00:00-06:00") - create dayjs applying browser timezone (+9 hours MSK)
// dayjs("2023-03-13T22:00:00-06:00").tz() - convert to default timezone (-9 hours MSK)
// dayjs.tz("2023-03-13T22:00:00-06:00") - parse with zone offset (+6 hours MSK)

// dayjs("2023-03-13") - create dayjs without applying browser timezone (+0 hours)
// dayjs("2023-03-13").tz() - convert to default timezone (-9 hours MSK) (2023-03-12 15:00)
// dayjs.tz("2023-03-13") - parse with zone offset (+0 hours MSK)

// dayjs("2023-04-01T20:00:00.095Z") - create dayjs applying browser timezone (+3 hours MSK)
// dayjs("2023-04-01T20:00:00.095Z").tz() - convert to default timezone (-6 hours MSK)
// dayjs.tz("2023-04-01T20:00:00.095Z") - parse with zone offset (+0 hours MSK)
function parseWithTimezone(value?: dayjs.Dayjs | null | string) {
  if (!value) return undefined;

  if (dayjs(value, "YYYY-MM-DD", true).isValid()) return dayjs.tz(value);

  return dayjs(value).tz();
}

function HolidayDate({ date }: { date: Dayjs }) {
  const { holidays, weekDays } = useWeekDaysAndHolidays(date.year());

  const weekDay = WeekdaysShort[date.day()];
  const nonWorking = weekDays?.some((d) => d.name == weekDay && !d.working);
  const holiday = holidays?.find((h) => date.isSame(h.date, "day"));

  return (
    <div
      className={classNames(
        "ant-picker-cell-inner",
        nonWorking ? "non-working-date" : undefined,
        holiday ? "holiday-date" : undefined
      )}
      title={holiday ? String(holiday.description) : undefined}
    >
      {date.date()}
    </div>
  );
}

export const DatePicker = ({
  value,
  showWeeks,
  disableDates,
  popupClassName,
  defaultTime,
  onChange,
  ...props
}: DatePickerProps) => {
  const intl = useIntl();
  const { antLocale } = useCurrentLocale();
  const firstDay = dayjs.localeData().firstDayOfWeek();

  return (
    <AntDatePicker
      popupClassName={classNames(
        popupClassName,
        showWeeks ? "week-panel" : undefined,
        "notranslate"
      )}
      locale={antLocale.DatePicker}
      dateRender={
        showWeeks
          ? (date) => (
              <div className="ant-picker-cell-inner">
                {date.day() == firstDay && (
                  <div
                    className="week-number"
                    title={intl.formatMessage(
                      { id: "weekNumber" },
                      { week: date.week() }
                    )}
                  >
                    {showWeeks === true
                      ? date.week()
                      : formatWeek(
                          date,
                          showWeeks.startedAt,
                          showWeeks.estimatedEndDate
                        )}
                  </div>
                )}
                <HolidayDate date={date} />
              </div>
            )
          : (date) => <HolidayDate date={date} />
      }
      format={DATE_FORMAT}
      value={parseWithTimezone(value)}
      placeholder={intl.formatMessage(messages.selectDate)}
      style={{ width: "100%" }}
      disabledDate={
        disableDates && showWeeks && showWeeks !== true
          ? (date) => {
              const startDate =
                disableDates === true
                  ? dayjs(showWeeks.startedAt).startOf("day")
                  : dayjs(disableDates);

              return showWeeks.estimatedEndDate
                ? !dayjs(date).isBetween(
                    startDate,
                    showWeeks.estimatedEndDate,
                    "date",
                    "[]"
                  )
                : dayjs(date).isBefore(startDate);
            }
          : undefined
      }
      onChange={(value, dateString) => {
        const val =
          defaultTime == "startOfDay" && value ? value.startOf("day") : value;
        if (onChange) onChange(val, dateString);
      }}
      {...props}
    />
  );
};

const RangePicker = ({
  value,
  popupClassName,
  ...props
}: RangePickerProps & { popupClassName?: string | undefined }) => {
  const { antLocale } = useCurrentLocale();

  return (
    <AntDatePicker.RangePicker
      locale={antLocale.DatePicker}
      style={{ width: "100%" }}
      format={props.picker === "month" ? MONTH_FORMAT : DATE_FORMAT}
      value={
        value
          ? [
              parseWithTimezone(value[0]) || null,
              parseWithTimezone(value[1]) || null,
            ]
          : undefined
      }
      popupClassName={classNames(popupClassName, "notranslate")}
      dateRender={(date) => <HolidayDate date={date} />}
      {...props}
    />
  );
};

export type RangePickerFormItemProps = {
  startName: NamePath;
  endName: NamePath;
  defaultDate?: any;
  value?: any;
} & RangePickerProps;

function RangePickerFormItem({
  startName,
  endName,
  defaultDate,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange,
  ...props
}: RangePickerFormItemProps) {
  const [defaultEndDate, setDefaultEndDate] = useState<any>();

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate(startName, endName)}>
      {({ getFieldValue, setFields }) => {
        return (
          <DatePicker.RangePicker
            defaultPickerValue={
              getFieldValue(startName)
                ? [dayjs(getFieldValue(startName)), defaultEndDate]
                : [dayjs(defaultDate), defaultEndDate]
            }
            value={
              getFieldValue(startName) && [
                getFieldValue(startName),
                getFieldValue(endName),
              ]
            }
            onChange={(values, formatString) => {
              if (onChange) onChange(values, formatString);

              setFields([
                { name: startName, value: values?.[0] },
                { name: endName, value: values?.[1] },
              ]);
            }}
            onCalendarChange={(values) => setDefaultEndDate(values?.[0])}
            {...props}
          />
        );
      }}
    </Form.Item>
  );
}

DatePicker.RangePicker = RangePicker;
DatePicker.RangePickerFormItem = RangePickerFormItem;

const YearPicker = ({
  value,
  onChange,
  popupClassName,
  ...props
}: Omit<DatePickerProps, "onChange"> & {
  onChange?(value: number | null): void;
}) => {
  const intl = useIntl();

  return (
    <AntDatePicker
      autoComplete="no"
      allowClear
      placeholder={intl.formatMessage(messages.selectYear)}
      value={value ? dayjs.tz(value.toString()) : undefined}
      picker="year"
      onChange={(value) =>
        onChange && onChange(value ? Number(dayjs(value).format("YYYY")) : null)
      }
      popupClassName={classNames(popupClassName, "notranslate")}
      {...props}
    />
  );
};

DatePicker.YearPicker = YearPicker;

export const DateTimePicker = (props: DatePickerProps) => (
  <DatePicker showTime format={`${DATE_FORMAT} ${TIME_FORMAT}`} {...props} />
);
