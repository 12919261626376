import { Divider, Space, Table, Typography } from "antd";
import { sumBy } from "lodash";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import {
  shouldUpdate,
  formatHours,
  formatPercent,
  roundUnit,
  formatUnitValue,
} from "../../../../lib/formats";
import { Form, InputNumber, RuleBuilder } from "../../../form";
import { WorkOrderContext } from "../WorkOrderContext";
import { CostCenterTableInput } from "./CostCenterTableInput";
import { WorkOrderCostCentersProps } from "./WorkOrderCostCenters";
import { ActivityMetricScope } from "../../../../lib/graphql";

export function DistributeHours({
  tableProps,
  columns,
  byPercentage,
  tableSelectProps,
  ...props
}: WorkOrderCostCentersProps) {
  const { workOrder, builder } = useContext(WorkOrderContext);

  return (
    <Form.Item
      shouldUpdate={shouldUpdate("costCenters", "employees", "machines")}
    >
      {() => {
        const costCenters = builder.costCenters.get();
        const employeeHours = builder.employees.getTotalHours();
        const machineHours = builder.machines.getTotalHours();
        const enteredEmployeeHours = roundUnit(
          sumBy(costCenters, "employeeHours")
        );
        const enteredMachineHours = roundUnit(
          sumBy(costCenters, "machineHours")
        );
        const employeePercent = roundUnit(
          sumBy(costCenters, "employeePercent") / 100.0
        );
        const machinePercent = roundUnit(
          sumBy(costCenters, "machinePercent") / 100.0
        );

        const allColumns = [...columns];

        if (employeeHours > 0) {
          allColumns.push(
            {
              title: (
                <FormattedMessage
                  id="workOrders.employeeHours.distribute"
                  defaultMessage="employeeHours"
                />
              ),
              width: "10rem",
              render: (_, c, index) =>
                builder.isReadonly || byPercentage ? (
                  formatHours(c.employeeHours)
                ) : (
                  <Form.Item
                    compact
                    name={builder.costCenters.fieldName(index, "employeeHours")}
                  >
                    <InputNumber
                      min={0}
                      step={0.5}
                      addonAfter={<FormattedMessage id="workUnits.abbr.hour" />}
                      onChange={() =>
                        builder.costCenters.calculateCostDistribution(false)
                      }
                    />
                  </Form.Item>
                ),
            },
            {
              title: <FormattedMessage id="percent" defaultMessage="percent" />,
              width: 150,
              render: (_, _cc, index) =>
                builder.isReadonly || !byPercentage ? (
                  <Form.Item
                    noStyle
                    shouldUpdate={shouldUpdate([
                      "costCenters",
                      index,
                      "employeePercent",
                    ])}
                  >
                    {({ getFieldValue }) =>
                      formatPercent(
                        (getFieldValue(
                          builder.costCenters.fieldName(
                            index,
                            "employeePercent"
                          )
                        ) || 0) / 100.0
                      )
                    }
                  </Form.Item>
                ) : (
                  <Form.Item
                    name={builder.costCenters.fieldName(
                      index,
                      "employeePercent"
                    )}
                    compact
                  >
                    <InputNumber
                      step={1}
                      min={0}
                      precision={2}
                      addonAfter="%"
                      onChange={() =>
                        builder.costCenters.distributeCostsByPercent()
                      }
                    />
                  </Form.Item>
                ),
            }
          );
        }

        if (machineHours > 0) {
          allColumns.push(
            {
              title: (
                <FormattedMessage
                  id="workOrders.machineHours.distribute"
                  defaultMessage="machineHours"
                />
              ),
              width: "10rem",
              render: (_, c, index) =>
                builder.isReadonly || byPercentage ? (
                  formatHours(c.machineHours)
                ) : (
                  <Form.Item
                    compact
                    name={builder.costCenters.fieldName(index, "machineHours")}
                  >
                    <InputNumber
                      min={0}
                      step={0.5}
                      addonAfter={<FormattedMessage id="workUnits.abbr.hour" />}
                      onChange={() =>
                        builder.costCenters.calculateCostDistribution(false)
                      }
                    />
                  </Form.Item>
                ),
            },
            {
              title: <FormattedMessage id="percent" defaultMessage="percent" />,
              width: 150,
              render: (_, _cc, index) =>
                builder.isReadonly || !byPercentage ? (
                  <Form.Item
                    noStyle
                    shouldUpdate={shouldUpdate([
                      "costCenters",
                      index,
                      "machinePercent",
                    ])}
                  >
                    {({ getFieldValue }) =>
                      formatPercent(
                        (getFieldValue(
                          builder.costCenters.fieldName(index, "machinePercent")
                        ) || 0) / 100.0
                      )
                    }
                  </Form.Item>
                ) : (
                  <Form.Item
                    name={builder.costCenters.fieldName(
                      index,
                      "machinePercent"
                    )}
                    compact
                  >
                    <InputNumber
                      step={1}
                      min={0}
                      precision={2}
                      addonAfter="%"
                      onChange={() =>
                        builder.costCenters.distributeCostsByPercent()
                      }
                    />
                  </Form.Item>
                ),
            }
          );
        }

        return (
          <>
            {!builder.isReadonly && (
              <Typography.Paragraph>
                <Space>
                  {employeeHours > 0 && (
                    <DistributionText
                      label={
                        <FormattedMessage
                          id="workOrders.employeeHours"
                          defaultMessage="employeeHours"
                        />
                      }
                      totalHours={employeeHours}
                      enteredHours={enteredEmployeeHours}
                      byPercentage={byPercentage}
                      totalPercent={employeePercent}
                    />
                  )}
                  {machineHours > 0 && employeeHours > 0 && (
                    <Divider type="vertical" />
                  )}
                  {machineHours > 0 && (
                    <DistributionText
                      label={
                        <FormattedMessage
                          id="workOrders.machineHours"
                          defaultMessage="machineHours"
                        />
                      }
                      totalHours={machineHours}
                      enteredHours={enteredMachineHours}
                      byPercentage={byPercentage}
                      totalPercent={machinePercent}
                    />
                  )}
                </Space>
              </Typography.Paragraph>
            )}

            <CostCenterTableInput
              tableSelectProps={{
                ...tableSelectProps,
                afterAdd: () =>
                  builder.costCenters.calculateCostDistribution(true),
              }}
              tableProps={{
                ...tableProps,
                summary: (costCenters) => {
                  return (
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        index={0}
                        colSpan={columns.length + (builder.isReadonly ? 1 : 2)}
                      >
                        <FormattedMessage id="total" defaultMessage="total" />
                      </Table.Summary.Cell>
                      {employeeHours > 0 && (
                        <Table.Summary.Cell index={1}>
                          <Form.Item
                            name="employeeHours"
                            compact
                            dependencies={[
                              [
                                "costCenters",
                                costCenters.length - 1,
                                "employeeHours",
                              ],
                            ]}
                            rules={[
                              RuleBuilder.custom(() => {
                                if (enteredEmployeeHours == employeeHours)
                                  return true;

                                return (
                                  <FormattedMessage
                                    id="form.validation.mustSumTo"
                                    defaultMessage="mustSumTo"
                                    values={{
                                      max: employeeHours,
                                      now: enteredEmployeeHours,
                                    }}
                                  />
                                );
                              }),
                            ]}
                          >
                            <Typography.Text
                              type={
                                employeeHours != enteredEmployeeHours
                                  ? "danger"
                                  : undefined
                              }
                            >
                              {formatHours(enteredEmployeeHours)}
                            </Typography.Text>
                          </Form.Item>
                        </Table.Summary.Cell>
                      )}
                      {employeeHours > 0 && (
                        <Table.Summary.Cell index={1}>
                          <Typography.Text
                            type={employeePercent !== 1 ? "danger" : undefined}
                          >
                            {formatPercent(employeePercent)}
                          </Typography.Text>
                        </Table.Summary.Cell>
                      )}
                      {machineHours > 0 && (
                        <Table.Summary.Cell index={2}>
                          <Form.Item
                            name="machineHours"
                            compact
                            dependencies={[
                              [
                                "costCenters",
                                costCenters.length - 1,
                                "machineHours",
                              ],
                            ]}
                            rules={[
                              RuleBuilder.custom(() => {
                                if (enteredMachineHours == machineHours)
                                  return true;

                                return (
                                  <FormattedMessage
                                    id="form.validation.mustSumTo"
                                    defaultMessage="mustSumTo"
                                    values={{
                                      max: machineHours,
                                      now: enteredMachineHours,
                                    }}
                                  />
                                );
                              }),
                            ]}
                          >
                            <Typography.Text
                              type={
                                machineHours != enteredMachineHours
                                  ? "danger"
                                  : undefined
                              }
                            >
                              {formatHours(enteredMachineHours)}
                            </Typography.Text>
                          </Form.Item>
                        </Table.Summary.Cell>
                      )}
                      {machineHours > 0 && (
                        <Table.Summary.Cell index={1}>
                          <Typography.Text
                            type={machinePercent !== 1 ? "danger" : undefined}
                          >
                            {formatPercent(machinePercent)}
                          </Typography.Text>
                        </Table.Summary.Cell>
                      )}
                      {workOrder.activity.activityMetrics
                        .filter(
                          (m) => m.scope == ActivityMetricScope.CostCenter
                        )
                        .map((m) => (
                          <Table.Summary.Cell index={1} key={m.id}>
                            {builder.isReadonly ? (
                              formatUnitValue(
                                builder.costCenters.metrics.getTotalMetric(
                                  m.metric.id
                                ),
                                m.metric.unit
                              )
                            ) : (
                              <InputNumber
                                step={0.1}
                                min={0}
                                value={builder.costCenters.metrics.getTotalMetric(
                                  m.metric.id
                                )}
                                addonAfter={m.metric.unit.abbr}
                                onChange={(value) =>
                                  builder.costCenters.metrics.distributeMetric(
                                    m.metric.id,
                                    value
                                  )
                                }
                              />
                            )}
                          </Table.Summary.Cell>
                        ))}
                      {!builder.isReadonly && <Table.Summary.Cell index={1} />}
                    </Table.Summary.Row>
                  );
                },
              }}
              columns={allColumns}
              {...props}
            />
          </>
        );
      }}
    </Form.Item>
  );
}

function DistributionText({
  label,
  enteredHours,
  totalHours,
  byPercentage,
  totalPercent,
}: {
  label: JSX.Element;
  enteredHours: number;
  totalHours: number;
  byPercentage?: boolean;
  totalPercent: number;
}) {
  return (
    <>
      {label}: <strong>{formatHours(totalHours)}</strong>
      <Divider type="vertical" />
      <FormattedMessage id="workOrders.leftToDistribute" />:{" "}
      <Typography.Text
        strong
        type={enteredHours === totalHours ? "success" : "danger"}
      >
        {!byPercentage
          ? formatHours(totalHours - enteredHours)
          : formatPercent(1 - totalPercent)}
      </Typography.Text>
    </>
  );
}
